<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName" @tab-click="handlerTab" style="margin-top: -20px;">
      <el-tab-pane :label="item.title" :name="item.id" v-for="(item,index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini">
      <el-table-column
          prop="user_id"
          min-width="240"
          label="推客信息">
        <template slot-scope="scope">
          <div class="member-box">
            <img :src="scope.row.avatar ? scope.row.avatar : source_domain + '/images/head.png'" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="grade_id"
          width="150"
          label="等级"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.grade_title">
            <div>{{scope.row.grade_title}}</div>
            <div>{{scope.row.grade_over_time}}</div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="no_settle"
          width="100"
          label="未结算佣金"
          align="center">
      </el-table-column>
      <el-table-column
          prop="commission_income"
          width="100"
          label="已结算佣金"
          align="center">
      </el-table-column>
      <el-table-column
          prop="reference_order_num"
          width="100"
          label="推广订单数"
          align="center">
      </el-table-column>
      <el-table-column
          prop="reference_num"
          width="100"
          label="推荐人数"
          align="center">
      </el-table-column>
      <el-table-column
          prop="reference_member_id"
          width="240"
          label="推荐人">
        <template slot-scope="scope">
          <div class="member-box" v-if="scope.row.reference_member_id>0">
            <img :src="scope.row.reference_avatar ? scope.row.reference_avatar : source_domain + '/images/head.png'" />
            <div>
              <div>{{scope.row.reference_name}}（{{scope.row.reference_user_id}}）</div>
              <div>{{scope.row.reference_mobile}}</div>
            </div>
          </div>
          <div v-else>总部</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="apply_txt"
          width="150"
          label="其他信息">
        <template slot-scope="scope">
          <div v-if="scope.row.apply_txt">
            <div v-for="(item,index) in scope.row.apply_txt" :key="index">{{item.key}}：{{item.value}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="加入时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          prop="status"
          label="审核状态"
          align="center"
          width="70">
        <template slot-scope="scope">
          <div class="red" v-if="scope.row.status==0">待审核</div>
          <div v-if="scope.row.status==1">通过</div>
          <div class="red" v-if="scope.row.status==2">拒绝</div>
          <div class="red" v-if="scope.row.status==-1">禁用</div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0">
            <el-popconfirm
                class="mg-rt-10"
                title="确认通过吗？"
                @confirm="applyItem(scope.row,1)">
              <el-button slot="reference" type="text" size="small">通过</el-button>
            </el-popconfirm>
            <el-button type="text" size="small" @click="showBox(scope.row,'refuse')">拒绝</el-button>
          </div>
          <div v-else-if="scope.row.status!=2">
            <el-popconfirm v-if="scope.row.status==1"
                class="mg-rt-10"
                title="确认禁用吗？"
                @confirm="changeStatus(scope.row,2)">
              <el-button slot="reference" type="text" size="small">禁用</el-button>
            </el-popconfirm>
            <el-popconfirm v-if="scope.row.status==-1"
                 class="mg-rt-10"
                 title="确认禁用吗？"
                 @confirm="changeStatus(scope.row,1)">
              <el-button slot="reference" type="text" size="small">启用</el-button>
            </el-popconfirm>
            <el-button type="text" size="small" @click="setGrade(scope.row)">设置等级</el-button>
            <el-button type="text" size="small" @click="changeParent(scope.row)">变更上级</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="设置等级"
        :visible.sync="showGradeModal"
        :destroy-on-close="true"
        width="500px">
      <guider-grade-set :guiderId="guider_id" :callFunc="function () { guider_id=0; showGradeModal=false; getList() }"></guider-grade-set>
    </el-dialog>
    <el-dialog
        title="变更上级"
        :visible.sync="showChangeModal"
        :destroy-on-close="true"
        width="950px">
      <member-list :type="1" :isGuider="true" :selectFunc="selectMember"></member-list>
    </el-dialog>
  </div>
</template>
<script>

import GuiderGradeSet from "@/components/Member/GuiderGradeSet";
import MemberList from "@/components/Member/MemberList";
export default {
  components: {
    GuiderGradeSet,
    MemberList
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      editItem: false,
      editType: '',
      tabList: [
        {id:'',title:'全部'},
        {id:'2',title:'审核通过'},
        {id:'1',title:'待审核'},
        {id:'3',title:'拒绝'},
        {id:'4',title:'禁用'},
      ],
      activeName: '',
      showGradeModal: false,
      guider_id: 0,
      showChangeModal: false
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    handlerTab(e) {
      this.activeName = this.tabList[e.index].id
      this.getList()
    },
    setGrade(item) {
      this.guider_id = item.id
      this.showGradeModal = true
    },
    changeParent(item) {
      this.guider_id = item.member_id
      this.showChangeModal = true
    },
    showBox(item,type) {
      this.editItem = item
      this.editType = type
      if(type=='refuse') {
        this.$prompt('请输入拒绝原因', '拒绝申请', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea'
        }).then(({ value }) => {
          if(!value) {
            this.fail('请输入拒绝内容')
            return false
          }
          this.applyItem(item,2,value)
        }).catch(() => {
        });
      }
    },
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords,
        status: _this.activeName
      }
      this.$api.distribution.guiderMember(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    applyItem(item,status,remark) {
      this.$api.distribution.guiderMemberVerify({id:item.id,status:status,remark:remark},res=>{
        if(res.errcode==0) {
          this.success(res.errmsg)
          setTimeout(r=>{
            this.getList()
          },1000)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeStatus(item,status) {
      this.$api.distribution.guiderMemberStatus({id:item.id,status:status},res=>{
        if(res.errcode==0) {
          this.success(res.errmsg)
          setTimeout(r=>{
            this.getList()
          },1000)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    selectMember(data) {
      this.$api.distribution.guiderMemberChangeParent({member_id:this.guider_id,guider_id:data.guider_id},res=>{
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.showChangeModal = false
          setTimeout(r=>{
            this.getList()
          },1000)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
